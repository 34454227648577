<div class="main-banner-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1  class="mobile-font-size-raion pc-font-size">RaiOn Inverters</h1>
                    <p style="color:#EFE6C0; font-size:medium">We distribute and provide service for RaiOn products.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-fffdfd  pt-100 " id="welcome-raion">
    <div class="container">
        <div class="section">
    <div class="parrafos">
        <div class="col-lg-6 welcome-title1"style="background-color:white;">
            <h1 style="font-size: 3rem ;"><span class="borders-bottom">RaiOn</span> inverters</h1>
        </div>
        <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2">
            <p> Two different product ranges according to your needs. The RaiOn inverters are fully compatible with BYD batteries.</p>
        </div>
    </div>
    <br>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-6 space-img">
          <div class="single-services-byd" style="height: 700px;width:25em">
            <div class="services-content">
                <h3 class="fuente-cool-light ">RaiOn J5</h3>
                <small class="borders-bottom fuente-pop-reg">Hybrid Inverter 5 kW</small>
            </div>
            <div class="container-img text-center" (mouseenter)="showText1()" (mouseleave)="hideText1()">
                <img src="assets/img/Raion_J5.png" class="ms-5" alt="services-img" height="400px">
              <span class="text">
                <div class="services-content">
                    <h3 class="fuente-cool-light">RaiOn J5</h3>
                    <small class="borders-bottom fuente-pop-reg">Hybrid Inverter 5 kW</small>
                </div>
                <ul class="fuente-pop-reg">
                <li>&bull; 5 kW Hybrid Inverter</li>
                <li>&bull; Off grid & On grid</li>
                <li>&bull; 9 units in parallel</li>
                <li>&bull; Compatible with BYD battery</li>
            </ul>
            <div class="d-flex justify-content-center">
              <a class="btn boton" style="margin-top: 30em; font-size:small;padding-right:5em; padding-left:5em" href="assets/pdf/RAION_J5_Datasheet.pdf" target="_blank" rel="noopener noreferrer">
                  DOWNLOAD TECHNICAL DATA SHEET →
              </a>
          </div>
            </span>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="single-services-byd" style="height: 700px;width:25em">
            <div class="services-content">
                <h3 class="fuente-cool-light">RaiOn M8</h3>
                <small class="borders-bottom fuente-pop-reg">Hybrid Inverter 8 kW</small>
              </div>
              <div class="container-img text-center" (mouseenter)="showText2()" (mouseleave)="hideText2()">
                <img src="assets/img/Raion_M8.png" class="ms-5" height="400px" alt="services-img">
                <span class="text">
                    <div class="services-content">
                        <h3 class="fuente-cool-light">RaiOn M8</h3>
                        <small class="borders-bottom fuente-pop-reg">Hybrid Inverter 8 kW</small>
                    </div>
                    <ul class="fuente-pop-reg">
                        <li>&bull; 8 kW Hybrid Inverter</li>
                        <li>&bull; Off grid & On grid</li>
                        <li>&bull; 6 units in parallel</li>
                        <li>&bull; Compatible with BYD battery</li>
                    </ul>
                    <div class="d-flex justify-content-center">
                      <a class="btn boton" style="margin-top: 30em;font-size:small;padding-right:5em; padding-left:5em" href="assets/pdf/RAION_M8_Datasheet.pdf" target="_blank" rel="noopener noreferrer">
                          DOWNLOAD TECHNICAL DATA SHEET →
                      </a>
                  </div>
                </span>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</div>
<div class="pb-70 pt-70" >
    <div class="section">
<div class="battery"  >
    <span>RAION </span>
    <span>ADVANTAGES</span>
    </div>         
    <br><br> 
    <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/parallel.svg" height="250" alt="services-img">
              </div>
              <div class=""><br>
                <h3 style="font-size: 1.8rem;">Parallel <br>
                    Connection</h3><br>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/ongrid offgrid.svg" height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h3 style="font-size: 1.8rem;">On-Grid or <br>
                     Off-Grid </h3><br>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/capacity.svg"  height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h3 style="font-size: 1.8rem;">5Kwh or 8Kwh <br>
                    Capacity</h3><br>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/hybrid.svg" height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h3 style="font-size: 1.8rem;">Hybrid <br> 
                    System</h3><br>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
<div class="pt-70 pb-70" style="background-color:#0B1F0D;">
    <div class="container">
        <div class="section">
    <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <h1 style="color: white; font-size:60px; text-align:start">Want to learn more about RaiOn products?</h1>
            <div class="col-lg-12 col-md-12 ">
                <a href="https://raion.energy/" target="_blank"><button type="submit"style="height: 40px; width:230px;" class="btn boton fuente-pop-reg" class="btn boton">VISIT RAION →</button></a>
            </div>
    </div>
    </div>
</div>
</div>
</div>