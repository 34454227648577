import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {UntypedFormBuilder, FormGroup, FormControl, Validators, UntypedFormGroup, UntypedFormArray, FormBuilder} from '@angular/forms';
import { ContactFormService } from 'src/app/contact-form.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-six',
  templateUrl: './home-six.component.html',
  styleUrls: ['./home-six.component.scss']
})
export class HomeSixComponent implements OnInit {
  public countrys: any[] = [];

  public formularioEnviado = false;
  public formLoading = false;
  public isSubmitted = false;
  public evento : any;
  public idEvento : any;
  public privacy_policy: string;
  public description: string;


   contactForm  = this.formBuilder.group({
      first_name:['', Validators.required],
      surname:['', [Validators.required]], 
      company_name:['', Validators.required], 
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      flexCheckChecked: [true, Validators.requiredTrue]

  });
  ngForm: any;

  copyText(textToCopy: string) {
    const tempInput = document.createElement('input');
    tempInput.setAttribute('value', textToCopy);
    document.body.appendChild(tempInput);
    
    tempInput.select();
    document.execCommand('copy');
    
    document.body.removeChild(tempInput);
    
    alert('Texto copiado: ' + textToCopy);
  }
  

  constructor(private titleService: Title,private viewportScroller: ViewportScroller,private route: ActivatedRoute, private router: Router,public formBuilder: FormBuilder,private contactService: ContactFormService, private translateService: TranslateService) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {

    this.contactService.getCountrys().subscribe({
      next: (r: any) => {
        this.countrys = r.message;
        console.log(this.countrys)
      }
    })

    this.route.params.subscribe(params => {
      if(params['id']){
        this.idEvento = params['id']
      }
    });
    this.contactService.getEvento({'name':this.idEvento}).subscribe({
      next: (r: any) => {
        if(r.status == 404) {
          this.router.navigate(['/event-not-found'])
        }
        this.evento = r.message;
        if(this.evento.idioma == "English") {
          this.translateService.use("en");
          this.privacy_policy = this.evento.layout.privacy_policy_text_english;
          this.description = this.evento.layout.description_english;
        }

        if(this.evento.idioma == "Deutsch") {
          this.translateService.use("de");
          this.privacy_policy = this.evento.layout.privacy_policy_text_german;
          this.description = this.evento.layout.description_german;
        }

        if(this.evento.idioma == "Español") {
          this.translateService.use("es");
          this.privacy_policy = this.evento.layout.privacy_policy_text_spanish;
          this.description = this.evento.layout.description_spanish;
        }

        if(this.evento.idioma == "Français") {
          this.translateService.use("fr");
          this.privacy_policy = this.evento.layout.privacy_policy_text_french;
          this.description = this.evento.layout.description_french;
        }

        if(this.evento.idioma == "Italiano") {
          this.translateService.use("it");
          this.privacy_policy = this.evento.layout.privacy_policy_text_italian;
          this.description = this.evento.layout.description_italian;
        }

        if(this.evento.idioma == "Polish") {
          this.translateService.use("po");
          this.privacy_policy = this.evento.layout.privacy_policy_text_polish;
          this.description = this.evento.layout.description_polish;
        }
        if(this.evento.idioma == "Other") {
          this.translateService.use("en");
          this.privacy_policy = this.evento.layout.privacy_policy_text_english;
          this.description = this.evento.layout.description_english;
        }

        this.titleService.setTitle(this.evento.nombre);

        console.log(this.evento);
      },
      error: (e) =>{
        if(e.status == 404) {
          this.router.navigate(['/event-not-found'])
        }
      }
    })
  }

    submit(){
    this.formLoading = true;
    this.isSubmitted = true;
    if (this.contactForm.invalid) {
        console.log(this.contactForm);
        this.formLoading = false;
        return;
      }
    
      let datos= {
        nombre: this.contactForm.value.first_name,
        apellido: this.contactForm.value.surname, 
        compania: this.contactForm.value.company_name, 
        email: this.contactForm.value.email,
        telefono: this.contactForm.value.telefono,
        pais: this.contactForm.value.country, 
        city: this.contactForm.value.city,
        evento: this.evento.name
      }

      this.contactService.createSolicitud(datos).subscribe({
        next: (r: any) => {
        console.log(r)
        this.formularioEnviado = true
        this.formLoading = false

      },
        error: (e) => console.log(e)
        
    })
   

    
}
}
