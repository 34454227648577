<div class="main-banner item-bg-three ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text text-start">
                    <h4>We Are Creative</h4>
                    <h1>The Best <span>Digital</span> Experience</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
                </div>
            </div>
        </div>
    </div>
</div>

