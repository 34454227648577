<div class="main-banner-home ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1 class="pc-font-size-mt mobile-font-size">Find our distributors!</h1>
                    <p style="color:#EFE6C0; font-size:large">Contact us for more information</p><br>

                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-fffdfd  pt-100 pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
    <br>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-6 ">
          <div id="map" class="map" style="height: 500px;"></div>
          
          <script
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyClpMFjMX_UTHmMTepnY0V_oI2_xjhQX-s&callback=initMap&v=weekly"
      defer>
    </script>
        </div>
      </div>
      <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <h1>Do you want to be our distributor?</h1>
        </div>
        <div class="col-lg-6 welcome-title2">
        </div>
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false">
    <div class="form-container">
        <div class="col-6">
            <div class="form-group">
              <label for="first-name"></label>
              <input minlength="3" maxlength="50" type="text" formControlName="first_name" placeholder="First Name" required>
              <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['first_name'].errors.required">
                Please enter Your first name
                </small>
            </div>  
            </div>
            <div class="form-group">
              <label for="surname"></label>
              <input minlength="3" maxlength="50" type="text" formControlName="surname" placeholder="Surname"required>
              <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['surname'].errors.required">
                Please enter Your surname
                </small>
            </div> 
            </div>
            <div class="form-group">
              <label for="company-name"></label>
              <input type="text" formControlName="company_name" placeholder="Company Name" required>
              <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                Please enter Your company name
                </small>
            </div> 
            </div>
            <div class="form-group">
              <label for="email"></label>
              <input type="email" formControlName="email" placeholder="Email"required>
              <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                Please enter Your email
                </small>
                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                    Please enter a valid Email
                    </small>
            </div> 
            </div>
            <div class="form-group mt-5">
              <input type="submit" value="Send →" class="btn boton" style="height: 2rem;padding-left:3em; padding-right:3em">
            </div>
        </div>
        <div class="col-6">
          <div class="form-group ms-3">
            <label for="message"></label>
            <textarea rows="12" formControlName="message" placeholder="Type your message here"></textarea>
            <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
              <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
              Please enter Type your message
              </small>
          </div> 
          </div>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="formularioEnviado == true">
      <div class="col-lg-12 col-md-6">
          <div class="form-group mb-3 text-center">
              <p>Your message has been send successfully.</p>
              </div>
      </div>
  </div>
    </div>
</div>

</div>
